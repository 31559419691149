import { Box, Button, Stack, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";
import { mainTheme } from "@/services/themes.ts";
import TagManager from "react-gtm-module";
import { useSelector } from "@/services/hooks.ts";
import { useNavigate } from "react-router-dom";
import { trackingUrlParam, getLocale } from "@/utils.ts";
import { Footer } from "@/pages/Demo/Footer";
import PlanFeatureDescription from "@/components/common/Plan Feature Description";
import { useEffect, useState } from "react";
import { Plan } from "@/services/models";
import { ApiService } from "@/services/api";
import { Step } from "@/pages/Demo/Step";

export default function DemoNormalIndex() {
  const user = useSelector((state) => state.user.user);
  const navigateTo = useNavigate();
  const locale = getLocale();

  const [plans, setPlans] = useState<Plan[]>([]);
  useEffect(() => {
    ApiService.fetchPlans().then((data) => {
      setPlans(data);
    });
  }, [])

  return (
    <Stack position="relative">
      <Stack direction="column" margin={3} marginTop={2} gap={0}>
        <Box textAlign="center">
          <img
            src={"/slack-imgs 1.png"}
            srcSet={"/slack-imgs 1@2x.png,/slack-imgs 1@3x.png"}
            alt="FACESWITCH"
            style={{
              backgroundColor: "white",
              width: "120px",
            }}
          />
        </Box>

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            color={mainTheme.palette.primary.main}
            fontSize={20}
          >
            {locale === "ja" ? (
              <>
                <span style={{ color: mainTheme.palette.primary.main }}>
                  <FormattedMessage id="demo2.normal.description.text3.a" />
                </span>
                <span style={{ color: "#000" }}>
                  <FormattedMessage id="demo2.normal.description.text3.b" />
                </span>
                <span style={{ color: mainTheme.palette.primary.main }}>
                  <FormattedMessage id="demo2.normal.description.text3.c" />
                </span>
                <span style={{ color: "#000" }}>
                  <FormattedMessage id="demo2.normal.description.text3.d" />
                </span>
              </>
            ) : (
              <>
                <span style={{ color: "#000" }}>
                  <FormattedMessage id="demo2.normal.description.text3" />
                </span>
              </>
            )}
          </Typography>
        </Box>
        <Box textAlign={"center"} marginTop={2}>
          <video
            style={{
              maxWidth: "min(342px, calc(100vw - 20px))",
              borderRadius: "16px",
              border: `solid 4px ${mainTheme.palette.primary.main}`,
              width: "100%",
            }}
            src="/demo_assets/demo_normal_movie_1.mp4"
            autoPlay
            muted
            playsInline
            loop
          />
        </Box>
        <Typography variant="body2" marginTop={1}>
          <FormattedMessage id="demo2.normal.description.text2" />
        </Typography>
        <Box
          marginTop={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            color={mainTheme.palette.primary.main}
            fontSize={20}
            fontWeight={500}
          >
            {locale === "ja" ? (
              <>
                <span style={{ color: mainTheme.palette.primary.main }}>
                  <FormattedMessage id="demo2.normal.description.text1.a" />
                </span>
                <span style={{ color: "#000" }}>
                  <FormattedMessage id="demo2.normal.description.text1.b" />
                </span>
                <span style={{ color: mainTheme.palette.primary.main }}>
                  <FormattedMessage id="demo2.normal.description.text1.c" />
                </span>
                <span style={{ color: "#000" }}>
                  <FormattedMessage id="demo2.normal.description.text1.d" />
                </span>
              </>
            ) : (
              <>
                <span style={{ color: "#000" }}>
                  <FormattedMessage id="demo2.normal.description.text1" />
                </span>
              </>
            )}
          </Typography>
        </Box>
        <Box textAlign={"center"} marginTop={2}>
          <video
            style={{
              maxHeight: "calc(100vh - 120px)",
              maxWidth: "min(342px, calc(100vw - 20px))",
              borderRadius: "16px",
              border: `solid 4px ${mainTheme.palette.primary.main}`,
              width: "100%",
            }}
            src="/demo_assets/demo_normal_movie_2.mp4"
            autoPlay
            muted
            playsInline
            loop
          />
        </Box>
        <Typography variant="body2" marginTop={1}>
          <FormattedMessage id="demo2.normal.description.text4" />
        </Typography>
        <Box
          marginTop={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            color={mainTheme.palette.primary.main}
            fontSize={20}
            fontWeight={500}
          >
            {locale === "ja" ? (
              <>
                <span style={{ color: "#000" }}>
                  <FormattedMessage id="demo2.normal.description.text5.a" />
                </span>
                <span style={{ color: mainTheme.palette.primary.main }}>
                  <FormattedMessage id="demo2.normal.description.text5.b" />
                </span>
                <span style={{ color: "#000" }}>
                  <FormattedMessage id="demo2.normal.description.text5.c" />
                </span>
                <span style={{ color: mainTheme.palette.primary.main }}>
                  <FormattedMessage id="demo2.normal.description.text5.d" />
                </span>
              </>
            ) : (
              <>
                <span style={{ color: "#000" }}>
                  <FormattedMessage id="demo2.normal.description.text5" />
                </span>
              </>
            )}
          </Typography>
        </Box>
        <Stack alignItems="center" marginTop={2}>
          <Step />
        </Stack>

        <Typography
          variant="subtitle2"
          fontSize={20}
          textAlign="center"
          marginTop={3}
        >
          <FormattedMessage id="demo2.normal.description.text8" />
        </Typography>

        <Box
          display="flex"
          gap="20px"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          mt={3}
          sx={{
            "&::after": {
              content: '""',
              display: "block",
              width: "161px",
            },
          }}
        >
          {[1, 2, 3].map((i) => (
            <Stack
              key={i}
              width="161px"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <img src={`/demo/reason${i}@3x.png`} style={{ width: "100px" }} />
              <Typography
                sx={{ marginTop: "4px" }}
                color="primary"
                variant="subtitle1"
              >
                <FormattedMessage id={`demo2.normal.description.text8-${i}.title`} />
              </Typography>

              <Typography color="textPrimary" variant="caption">
                <FormattedMessage
                  id={`demo2.normal.description.text8-${i}.description`}
                />
              </Typography>
            </Stack>
          ))}
        </Box>


        <Stack
          marginY={3}
          id="pricing"
        >
          <Typography
            variant="subtitle2"
            fontSize={20}
            textAlign="center"
            mb={2}
          >
            <FormattedMessage id="user.plan.details" />
          </Typography>

          <PlanFeatureDescription
            plans={plans}
            disabledTitle
          />
        </Stack>

        <Stack mb={3} gap={2}>
          <Typography
            variant="subtitle1"
            textAlign="center"
          >
            <FormattedMessage id="demo.available_card" />
          </Typography>

          <Box textAlign="center" marginTop={1}>
            <img
              src={"/available_card@2x.png"}
              srcSet={
                "/available_card@2x.png 2x, /available_card@3x.png 3x"
              }
              alt="available_card"
              style={{
                backgroundColor: "none",
                width: "105px",
              }}
            />
          </Box>
        </Stack>

        <Footer />
        <Box
          position="fixed"
          bottom={0}
          left={0}
          width="100%"
          sx={{
            background: "rgba(255, 255, 255, 0.96)",
            boxShadow:
              "0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);",
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            gap={0}
            padding={2}
            alignItems="center"
          >
            <Box justifyContent="center" display="flex">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                sx={{ gap: 1 }}
                onClick={() => {
                  if (user) {
                    navigateTo("/users/plan");
                  } else {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "demo_show_register_dialog",
                      },
                    });
                    const queryWithHash = trackingUrlParam();
                    navigateTo(`/users/signup${queryWithHash}`);
                  }
                }}
              >
                {user ? (
                  <FormattedMessage id="demo.upgrade" />
                ) : (
                  <>
                    <Stack direction="row" alignItems="center">
                      ＼
                      <Box
                        sx={{
                          fontSize: "11px",
                          lineHeight: "14px",
                          fontWeight: 500,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <FormattedMessage id="demo.easy" />
                      </Box>
                      ／
                    </Stack>
                    <FormattedMessage id="demo.register" />
                  </>
                )}
              </Button>
            </Box>
            <Box justifyContent={"center"} display="flex">
              <Button
                size="small"
                onClick={() => {
                  if (user) {
                    navigateTo("/");
                  } else {
                    const queryWithHash = trackingUrlParam();
                    navigateTo(`/users/login?${queryWithHash}`);
                  }
                }}
              >
                <FormattedMessage id="demo.login" />
              </Button>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
