import { isAdult } from "@/constants";
import { Divider, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const Footer = () => {
  const pathRoot = isAdult ? '' : '/normal'
  return (
    <>
      <Stack
        component="footer"
        paddingX={2}
        mb="80px"
      >
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Stack direction="row" gap="20px">
          <a href={`${pathRoot}/terms`} style={{ textDecoration: 'none' }}>
            <Typography
              fontSize="12px"
              color="#000000"
            >
              <FormattedMessage id="sidebar.terms" />
            </Typography>
          </a>
          <a href={`${pathRoot}/faq`} style={{ textDecoration: 'none' }}>
            <Typography
              fontSize="12px"
              color="#000000"
            >
              <FormattedMessage id="sidebar.faq" />
            </Typography>
          </a>
          <a href={`${pathRoot}/demo#pricing`} style={{ textDecoration: 'none' }}>
            <Typography
              fontSize="12px"
              color="#000000"
            >
              <FormattedMessage id="sidebar.pricing" />
            </Typography>
          </a>
          <a href={`${pathRoot}/contact`} style={{ textDecoration: 'none' }}>
            <Typography
              fontSize="12px"
              color="#000000"
            >
              <FormattedMessage id="sidebar.contact" />
            </Typography>
          </a>
        </Stack>
      </Stack>
    </>
  )
}