import { Button, List, ListItem } from '@mui/material';

import AppBar from "@/components/AppBar";
import { useDispatch } from '@/services/hooks';
import { useEffect, useLayoutEffect } from 'react';
import { getUserDetail } from '@/services/actions/userActions';
import CurrentPlan from '@/components/common/CurrentPlan';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';


const UserPlanFailed = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  // 直接URLを入力してアクセスした場合、マイページにリダイレクトする
  const isDirectAccess = import.meta.env.MODE !== 'localhost'
    && !document.referrer.includes('https://secure.tc-service.net')
    && !document.referrer.includes('https://grand-pay.payment-gateway.asia')
  useLayoutEffect(() => {
    if (isDirectAccess) {
      navigateTo('/mypage');
    }
  }, []);

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch])

  return (
    <AppBar title={<FormattedMessage id="user.plan_failed.title" />}>
      <div style={{ width: '100%', height: '100%', position: 'relative', background: 'white' }}>

        <List sx={{ padding: 0 }}>
          <CurrentPlan />

          <ListItem sx={{ paddingTop: '24px', paddingBottom: 0, }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '100%',
                padding: '8px 22px',
              }}
              onClick={() => {
                navigateTo('/');
              }}
            >
              <FormattedMessage id="user.plan_failed.home" />
            </Button>
          </ListItem>
          <ListItem sx={{ paddingTop: '24px' }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                width: '100%',
                padding: '8px 22px',
              }}
              onClick={() => {
                navigateTo('/mypage');
              }}
            >
              <FormattedMessage id="user.plan_failed.mypage" />
            </Button>
          </ListItem>
        </List >

      </div >
    </AppBar>
  );
};

export default UserPlanFailed;