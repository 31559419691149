import { v4 as uuidv4 } from "uuid";

export interface Character {
  id: number;
  name: string;
  url: string;
  is_public: boolean;
  is_editable?: boolean;
  owner_id?: number;
  created_at: string;
  is_limited: boolean;
  genre_ids: number[];
}

export interface MediaUpdate {
  url?: string;
  media_type?: string;
  title?: string;
  file?: File;
  sequence?: number;
  local_path?: string;
}

export interface SceneImage {
  character_id: number;
  original_scene_id: number;
  s3_url: string;
}

export interface Scene {
  id: number;
  title?: string;
  character?: Character;
  posted_at: string;
  is_favorable?: boolean;
  is_favorited?: boolean;
  is_tutorial: boolean;
  is_switch_trial?: boolean;
  point?: number;
  thumbnail_start_sec?: number;

  s3_path: string;
  uid: string;
  view_uid?: string;
  genres?: Genre[];
  scene_images?: SceneImage[]
  swapped_character_ids?: number[]
  weekly_rank?: number
  overall_rank?: number
  duration?: number
}

export interface SwappedImageHistory {
  character_id: number;
  original_scene_id: number;
  user_id: number;
  s3_urls: string[];
  character: Character;
  original_scene: Scene;
  created_at: string;
}

export interface SwitchedScene {
  id: number;
  title?: string;
  character: Character;
  posted_at: string;
  is_favorable?: boolean;
  is_favorited?: boolean;
  is_tutorial: boolean;
  is_switch_trial?: boolean;
  is_feedback_complete: boolean;
  s3_path: string;
  uid: string;
  is_expired: boolean;
  thumbnail_url?: string;
  duration?: number;
}

interface UserRemainings {
  id: number;
  user_id: string;
  plan_remaining_swaps: number;
}

export interface UserDetail {
  plan_name: string;
  user_remainings: UserRemainings;
  character_count: number;
  favorite_count: number;
  user_items: Item[];
  is_continue_paid: boolean;
  switch_trial_completed: boolean;
  paid_expired_at: string;
  next_update_date: string;
  feedback_completed: boolean;
  character: {
    plan_limit: number
    plan_remain: number
  };
  swap: {
    plan_limit: number
    plan_remain: number
    item_remain: number
  };
  favorite: {
    plan_limit: number
    plan_remain: number
  }
  has_paid: boolean;
  is_compensation_target: boolean; // TODO: 決済復活キャンペーン終了したら消す 決済不具合の補填対象かどうか
  timesale_remain_sec: number;
}

export interface User {
  id: number;
  email: string;
  is_free_plan: boolean;
  is_rewrited_plan: boolean;
  has_password: boolean;
  hash_id: string;
  switch_trial_completed: boolean;
  key: string;
  name?: string;
  phone?: string;
}

// 形式が正しければtrueを返す
export const validateUserName = (name: string) => {
  return !name.match(/[^a-zA-Z ]/)
}

export const normalizePhoneNumber = (phone: string) => {
  return phone.replace(/[^\-0-9]/g, '') // 許容していない文字列の削除
    .replace(/^-+/, '') // 先頭のハイフンの削除
    .replace(/-{2,}/g, '-') // 連続するハイフンの削除
}

export interface UserUpdate {
  name?: string;
  phone?: string;
}

export interface VerifyResponse {
  user: User;
}

export interface GenerateResponse {
  success: boolean;
  error_type: string;
}
export interface RegenerateResponse {
  success: boolean;
  error_type: string;
}
export interface CheckGenerateStatusResponse {
  swaps: {
    id: number;
    status: string;
    progress: number;
    job_status: string;
  }[];
}

export interface CheckReuploadingStatusResponse {
  swaps: {
    id: number;
    status: string;
  }[];
}

export interface Item {
  id: number;
  name: string;
  price: string;
  max_swap: number;
  purchase_limit? : number | null;
  payment_service_type: 'techcoin' | 'grandpay' | null;
}

export interface Plan {
  id: number;
  name: string;
  price: string;
  max_videos_per_month: number;
  max_characters: number;
  max_favorite_switch: number;
  recur_id: string;
}

type PurchaseType = "recurring" | "item" | "downgrade"

export interface Purchase {
  id: number;
  user_id: number;
  created_at: string;
  name: string;
  amount_yen: number | null;
  amount_dollar: number | null;
  key: string;
  purchase_type: PurchaseType
}
export const createPurchaseTemporaryId = () => {
  return uuidv4();
}

export interface CFStreamSignedItem {
  token: string;
  title: string;
  video_uid: string;
  thumbnail_start_sec: number;
}

export interface Notice {
  id?: number,
  start_date: string | null | undefined,
  end_date: string | null | undefined,
  title: string,
  category: string,
  body: string,
  display_target: string,
  is_published: boolean,
  is_important: boolean,
  is_read?: boolean,
}

export interface Genre {
  id: number,
  name: string,
  order: number,
  category: string,
}

export const BannerDisplay = {
  always: '常に表示',
  one_time_item: '一度きりアイテム使用前',
  free_plan: 'フリープランユーザー',
  paid_plan: '課金プランユーザー',
}

export type BannerDisplayType = keyof typeof BannerDisplay;


export interface Banner {
  id: number,
  name: string,
  is_public: boolean,
  display_type: BannerDisplayType,
  public_path: string,
  link_url: string | null | undefined,
}

export interface TCPreorder {
  id: number,
  user_id: number,
  plan_id: number | null,
  item_id: number | null,
  temporary_id: string | null,
  amount_yen: number,
}

export type FeedbackType = "unanswered" | "positive" | "negative"
