import { Box, ButtonBase, Typography } from "@mui/material";
import { color, mainTheme } from "@/services/themes.ts";

interface Props {
  disabled?: boolean;
  label?: string;
  onClick: () => void;
  selected?: boolean;
}

export default function SmallGenreButton(props: Props) {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        borderRadius: '18px',
      }}
    >
      <Box
        width="fit-content"
        minWidth="max-content"
        sx={{
          border: '1px solid #00000033',
          padding: '5px 8px 5px 8px',
          borderRadius: '18px',
          backgroundColor: props.selected ? mainTheme.palette.primary.main : props.disabled ? '#d0d0d0' : 'white',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="body1"
            fontSize="11px"
            sx={{
              lineHeight: '1',
              fontWeight: 400,
              color: props.selected ? 'white' : props.disabled ? mainTheme.palette.text.primary : color.main,
            }}
          >
            #
          </Typography>
          <Typography
            variant="body1"
            fontSize="11px"
            sx={{
              lineHeight: '1',
              fontWeight: 400,
              color: props.selected ? 'white' : mainTheme.palette.text.primary,
            }}
          >
            {props.label}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  )
}
