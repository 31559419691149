import SceneComp from "@/components/Scene";
import { ApiService } from "@/services/api";
import { useDispatch, useSelector, useUrlParams } from "@/services/hooks";
import { Scene } from "@/services/models";
import { TUTORIAL_STEP } from "@/services/tutorial";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";


const SuggestedScenes = () => {
  const [suggestedScenes, setSuggestedScenes] = useState<Scene[]>([]);
  const completeToggle = useSelector((state) => state.common.progress.complete_toggle);
  const { user, detail } = useSelector((state) => state.user);
  const tutorialStep = useSelector((state) => state.common.tutorial_step);
  const isSwitchTutorial = tutorialStep === TUTORIAL_STEP.switch;
  const dispatch = useDispatch();
  const { getUrlParam } = useUrlParams();
  const hasFilter = !!getUrlParam('filter');

  // ABテストの表示対象かどうか
  // idの下一桁が0~7のユーザーに表示する

  // 一旦でなくさせる
  const isSuggestTestTarget = user?.id && user.id % 10 < 8 && false;

  useEffect(() => {
    if (!user?.is_free_plan
      || isSwitchTutorial
      || !detail?.switch_trial_completed
      || !isSuggestTestTarget
    ) {
      setSuggestedScenes([]);
      dispatch({ type: 'HAS_NO_SUGGESTED_SCENE' });
      return;
    }
    ApiService.getSuggestedScenes()
      .then((data) => {
        setSuggestedScenes(data);
        if (data.length > 0) {
          dispatch({ type: 'HAS_SUGGESTED_SCENE' });
        } else {
          dispatch({ type: 'HAS_NO_SUGGESTED_SCENE' });
        }
      })
      .catch(() => setSuggestedScenes([]));
  }, [tutorialStep, user, user?.is_free_plan, detail?.switch_trial_completed, isSuggestTestTarget, isSwitchTutorial, completeToggle]);

  return suggestedScenes.length > 0 && !hasFilter && (
    <Stack p={2} sx={{ backgroundColor: "rgba(243, 243, 243, 1)" }} mb={3}>
      <Typography
        variant='h6'
        color="primary"
        fontWeight={700}
        fontSize="18px"
      >
        <FormattedMessage id="home.suggested_scene.original.title" />
      </Typography>
      {suggestedScenes.map((scene, index) => (
        <Box key={`${scene.id}_${index}`}>
          <SceneComp
            index={index}
            scene={scene}
            showDivider={index < suggestedScenes.length - 1}
            isShowGenreSearch
            sceneType="myscene"
            onFavoriteChanged={() => { }}
            showCharacter={false}
            bgColor="rgba(0, 0, 0, 0)"
            isSuggested
          />
        </Box>
      ))}
    </Stack>
  )
}

export default SuggestedScenes;