import { isAdult } from "@/constants";
import { ArrowDropDown } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const Step = () => {
  const steps = [1, 2, 3];
  return (
    <Stack
      width="327px"
      alignItems="center"
      gap={2}
      py={5}
      px={2}
      sx={{
        backgroundColor: "rgba(242, 242, 242, 1)",
      }}
    >
      {steps.map((step, index) => (
        <>
          <Typography
            color="primary"
            fontFamily="Roboto"
            fontWeight={500}
            fontSize="13px"
            width="100%"
            textAlign="center"
            padding={0.5}
            border="1px solid"
            borderRadius="50px"
          >
            STEP {step}
          </Typography>
          <Box textAlign="center">
            <img
              src={`/demo_assets/demo_step_${step}@2x.png`}
              srcSet={
                `/demo_assets/demo_step_${step}@2x.png 2x, /demo_assets/demo_step_${step}@3x.png 3x`
              }
              alt={`STEP ${step}`}
              width="80px"
            />
            <Typography
              fontFamily="Noto Sans JP"
              fontWeight={500}
              fontSize="16px"
              textAlign="center"
            >
              <FormattedMessage id={`demo2.${isAdult ? "" : "normal."}step${step}`} />
            </Typography>
          </Box>
          {index < steps.length - 1 && (
            <ArrowDropDown color="primary" sx={{ padding: "-14px" }} fontSize="large" />
          )}
        </>
      ))}
    </Stack>
  )
}