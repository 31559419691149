import _ from 'lodash';
import SceneComp from "@/components/Scene";
import SceneWithImageComp from "@/components/Scene/Image";
import SceneSampleComp from "@/components/Scene/sample.tsx";
import { ApiService } from "@/services/api";
import { useSelector, useUrlParams } from "@/services/hooks";
import { CFStreamSignedItem, Scene } from "@/services/models";
import { TUTORIAL_STEP } from "@/services/tutorial";
import { Box, BoxProps, Button, Divider, Stack, StackProps, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { FormattedMessage } from "react-intl";
import { useNavigate } from 'react-router-dom';
// import BottomUpgradeBanner from '@/components/Banner/BottomUpgradeBanner';
import { backToPlanPagePath } from '@/constants';
import SuggestedScenes from '@/pages/Home/SuggestedScenes';

const LIMIT = 10;

type PropTypes = {
  isShowGenreSearch: boolean;
}

export default function Recommend(props: PropTypes) {
  const { getUrlParam } = useUrlParams();
  const sort = getUrlParam('sort') || 'recommend';
  const filter = getUrlParam('filter');

  const {
    isShowGenreSearch,
  } = props;
  const navigateTo = useNavigate();
  const tutorialStep = useSelector((state) => state.common.tutorial_step);
  const isSwitchTutorial = tutorialStep === TUTORIAL_STEP.switch;

  const { user, detail } = useSelector((state) => state.user);
  const { is_two_column } = useSelector((state) => state.common);
  const isTwoColumn = is_two_column && (!tutorialStep || tutorialStep === TUTORIAL_STEP.done);


  const twoColumnStyle: Partial<StackProps> = isTwoColumn ? {
    direction: 'row',
    flexWrap: 'wrap',
    gap: 2,
  } : {}

  const twoColumnBoxStyle: Partial<BoxProps> = isTwoColumn ? {
    width: 'calc(50% - 8px)',
  } : {}

  const [page, setPage] = useState(1);
  const [scenes, setScenes] = useState<Scene[]>([]);
  const [trialScenes, setTrialScenes] = useState<Scene[]>([]);
  const [isLastScene, setIsLastScene] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isSampleLastScene, setIsSampleLastScene] = useState(false);
  const [samplePage, setSamplePage] = useState(1);
  const [sampleScenes, setSampleScenes] = useState<CFStreamSignedItem[]>([]);

  const canShowSwitchTrial = trialScenes.length > 0 && user &&
    user.is_free_plan && !isSwitchTutorial && !detail?.switch_trial_completed;

  const init = () => {
    setPage(1);
    setIsLastScene(false);
    fetchScenes();
    fetchTrialScenes();
    setSamplePage(1);
    setIsSampleLastScene(false);
    fetchSampleScenes();
  };

  const fetchTrialScenes = () => {
    if (!user?.is_free_plan || isSwitchTutorial || detail?.switch_trial_completed) {
      setTrialScenes([]);
      return;
    }
    ApiService.getTrialScenes()
      .then((data) => {
        setTrialScenes(data);
      })
      .catch(() => setTrialScenes([]));
  }

  const fetchSampleScenes = () => {
    if (isSwitchTutorial || !user?.is_free_plan) {
      setSampleScenes([]);
      setSamplePage(1);
      setIsSampleLastScene(true);
      return;
    }
    ApiService.fetchSampleScenes(0).then((data) => {
      if (data.length < LIMIT) {
        setIsSampleLastScene(true);
      } else {
        setIsSampleLastScene(false);
      }
      setSampleScenes(data);
      setSamplePage(1);
    }).catch(() => setSampleScenes([]));
  }

  const fetchScenes = () => {
    if (isSwitchTutorial) {
      ApiService.getTutorialScenes().then((data) => {
        setIsLastScene(true);
        setScenes(data);
      }).catch(() => setScenes([]));
      return;
    }

    const option = {
      filter,
      sort,
      page: 0
    };

    // 何らかの絞り込みがある時
    if (filter) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'use_genre_search',
          user_id: user?.id,
          filter,
        }
      });
    }

    ApiService.getScenes(option)
      .then((data) => {
        if (data.length < LIMIT) {
          setIsLastScene(true);
        }
        setScenes(data);
      })
      .catch(() => setScenes([]));
  }

  const fetchNextSample = useCallback(() => {
    setLoadingMore(true);
    const fetchData = ApiService.fetchSampleScenes
    fetchData(samplePage)
      .then((data) => {
        let isLastPage = false;
        if (data.length < LIMIT) {
          setIsSampleLastScene(true);
          isLastPage = true;
        }
        TagManager.dataLayer({
          dataLayer: {
            event: "click_load_more_sample",
            user_id: user?.id,
            page: page,
            is_last_page: isLastPage,
          }
        });
        setSamplePage((before) => {
          return before + 1;
        });
        setSampleScenes([...sampleScenes, ...data]);
        setLoadingMore(false);
      })
  }, [sampleScenes, samplePage]);

  const fetchNext = useCallback(() => {
    setLoadingMore(true);
    const option = {
      filter,
      sort,
      page,
    };

    ApiService.getScenes(option)
      .then((data) => {
        let isLastPage = false;
        if (data.length < LIMIT) {
          setIsLastScene(true);
          isLastPage = true;
        }
        TagManager.dataLayer({
          dataLayer: {
            event: "click_load_more",
            user_id: user?.id,
            page: page,
            is_last_page: isLastPage,
          }
        });
        setPage((before) => {
          return before + 1;
        });
        setScenes([...scenes, ...data]);
        setLoadingMore(false);
      });
  }, [scenes, page]);

  useEffect(() => {
    // fetchScenes();
    // fetchTrialScenes();
    // fetchSampleScenes();
    init();
  }, [tutorialStep, user, sort, filter, detail?.is_continue_paid, detail?.paid_expired_at, detail?.switch_trial_completed, detail?.plan_name]);

  return (
    <>
      <SuggestedScenes />
      {canShowSwitchTrial ? (
        <div style={{ backgroundColor: '#FAF7E1', padding: '16px' }}>

          <>
            <Stack mt={2}>
              <img style={{ width: '100%' }} src="/switch_trial_banner@3x.png"
                srcSet="/switch_trial_banner@3x.png 3x,/switch_trial_banner@2x.png 2x" alt="banner" />
            </Stack>
            <Box mt={2}>
              <Typography
                variant="body2"
                sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
              >
                <FormattedMessage id="home.switch_trial_text" />
              </Typography>
            </Box>
            <Divider sx={{ mt: 2 }} />
          </>

          <Stack {...twoColumnStyle}>
            {trialScenes.map((scene, index) => (
              <Box
                key={`${scene.id}_${index}`}
                {...twoColumnBoxStyle}
              >
                {/*<SceneWithImageComp
                isTwoColumn={isTwoColumn}
                isTutorial={isSwitchTutorial}
                index={index}
                scene={scene}
                setScenes={setTrialScenes}
                showDivider={index < scenes.length - 1}
                bgColor="#FAF7E1"
                sceneType="myscene"
              />*/}

                <SceneComp
                  isTwoColumn={isTwoColumn}
                  isTutorial={isSwitchTutorial}
                  index={index}
                  scene={scene}
                  showDivider={false}
                  isShowGenreSearch={false}
                  sceneType="myscene"
                  bgColor="#FAF7E1"
                  onFavoriteChanged={() => { }}
                  showCharacter={false} />
              </Box>
            ))}
          </Stack>
        </div >
      ) : null
      }

      <Stack
        direction="column"
        spacing={0}
        marginTop={0}
        paddingLeft={2}
        paddingRight={2}
        gap={3}
        {...twoColumnStyle}
      >
        {scenes.map((scene, index) => !scene.is_switch_trial && (
          <Box key={`${scene.id}_${index}`} {...twoColumnBoxStyle}>
            {
              (scene.is_tutorial || user?.is_free_plan) ?
                <SceneComp
                  isTwoColumn={isTwoColumn}
                  isTutorial={isSwitchTutorial}
                  index={index}
                  scene={scene}
                  showDivider={index < scenes.length - 1}
                  isShowGenreSearch={isShowGenreSearch}
                  sceneType="myscene"
                  onFavoriteChanged={() => { }}
                  showCharacter={false} /> :
                <SceneWithImageComp
                  isTwoColumn={isTwoColumn}
                  isTutorial={isSwitchTutorial}
                  index={index}
                  scene={scene}
                  setScenes={setScenes}
                  showDivider
                  isShowGenreSearch={isShowGenreSearch}
                  sceneType="myscene"
                />
            }
          </Box>
        ))}

        {scenes.length > 0 && !isLastScene && (
          <Stack
            marginTop={2}
            width="100%"
          >
            <Button
              onClick={fetchNext}
              variant="outlined"
              fullWidth
              disabled={loadingMore}
              sx={{ borderColor: 'rgba(240, 98, 146, 1)', background: 'rgba(250, 247, 225, 1)' }}
            >
              <FormattedMessage id="home.load_more" />
            </Button>
          </Stack>
        )}
      </Stack>


      {/* ここから通常エリア */}


      {/* TODO: 決済が停止されているので表示しない */}
      {/* {
        sampleScenes.length === 0 && !isSwitchTutorial && user?.is_free_plan &&
        <BottomUpgradeBanner />
      } */}
      <Box margin={2} textAlign="center">
        {sampleScenes.length > 0 && (
          <>
            <Stack paddingTop={3} paddingBottom={3}>
              <Stack mb={3}>
                <img style={{ width: '100%' }} src="/home/MoreBan@2x.png" srcSet="/home/MoreBan@3x.png 2x,/home/MoreBan@3x.png 3x" alt="more banner" />
              </Stack>
              <Typography
                variant="body2"
                marginBottom={2}
              >
                <FormattedMessage id="mypage.sample_scene.before" />
              </Typography>
              <Button
                onClick={() => {
                  navigateTo(backToPlanPagePath)
                }}
                fullWidth
                variant="contained"
                size="large"
              >
                <FormattedMessage id="character_swap.upgrade" />
              </Button>
            </Stack>
            <Divider />
          </>
        )}
      </Box>
      <Stack direction="column" spacing={0} marginTop={0} paddingLeft={2} paddingRight={2} gap={3} {...twoColumnStyle}>
        {sampleScenes.map((video, index) => (
          <Box key={`${video.video_uid}_${index}`} {...twoColumnBoxStyle}>
            <SceneSampleComp
              video={video}
              showDivider={index < sampleScenes.length - 1}
            />

            {
              (index + 1) % 10 === 0 && (
                <>
                  <Stack paddingTop={3} paddingBottom={3}>
                    <Typography
                      variant="body2"
                      marginBottom={2}
                    >
                      <FormattedMessage id="mypage.sample_scene" />
                    </Typography>
                    <Button
                      onClick={() => {
                        navigateTo('/users/plan')
                      }}
                      fullWidth
                      variant="contained"
                      size="large"
                    >
                      <FormattedMessage id="character_swap.upgrade" />
                    </Button>
                  </Stack>
                  <Divider />
                </>
              )}
          </Box>
        ))}
        {sampleScenes.length > 0 && !isSampleLastScene && (
          <Stack
            marginTop={2}
            width="100%"
          >
            <Button
              onClick={fetchNextSample}
              variant="outlined"
              fullWidth
              disabled={loadingMore}
              sx={{ borderColor: 'rgba(240, 98, 146, 1)', background: 'rgba(250, 247, 225, 1)' }}
            >
              <FormattedMessage id="home.load_more" />
            </Button>
          </Stack>
        )}
      </Stack>
      {isSwitchTutorial && <Stack height="50px" />}
    </>
  );
}
