import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  ChevronLeft,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import AppBar from "@/components/AppBar";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "@/services/hooks";
import { ApiService } from "@/services/api";
import { Notice } from "@/services/models";
import { readNotice } from "@/services/actions/commonActions";

const NoticeComp: React.FC = () => {
  const dispatch = useDispatch();
  const { noticeId } = useParams<{ noticeId: string }>();
  const [notice, setNotice] = useState<Notice | null>(null)
  const navigateTo = useNavigate();

  useEffect(() => {
    if (!noticeId) {
      return;
    }
    ApiService.fetchNotice(noticeId).then((data) => {
      setNotice(data);
      console.log(data);
      dispatch(readNotice(noticeId)).catch(() => {
        console.log('read notice error');
      });
    }).catch(() => {
      console.log('fetch notice error');
    });
  }, [noticeId]);

  return (
    <AppBar title={<FormattedMessage id="notice.view.title" />}>
      <Box
        justifyContent="flex-start"
        display="flex"
        padding={2}
      >
        <IconButton
          onClick={() => navigateTo(-1)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            width: 'fit-content',
            borderRadius: '8px',
          }}
        >
          <ChevronLeft />
          <Typography variant="caption" lineHeight={1}>
            <FormattedMessage id="notice.view.back" />
          </Typography>
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ padding: 0 }}>

        <ListItem
          disablePadding
          sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}>
          <ListItemButton>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={{
                    color: '#000000DE',
                    fontWeight: 500,
                  }}
                >
                  {notice?.title}
                </Typography>
              }
              secondary={
                <Typography
                  variant="caption"
                  sx={{ color: '#000000DE' }}
                >
                  {dayjs(notice?.start_date).format('YYYY/MM/DD HH:mm')}
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Stack padding={2}>
        <Typography
          variant="body2"
          sx={{ whiteSpace: 'break-spaces' }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: notice?.body || '',
            }}
          />
        </Typography>
      </Stack>
    </AppBar >
  );
};

export default NoticeComp;
