import _ from "lodash";
import dayjs from "dayjs";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Button, Box, Divider, Stack, ButtonBase, BoxProps } from "@mui/material";

import { resolveGenreParam } from "@/utils";
import { Scene } from "@/services/models";
import { openCharacterNewDialog, openSnackbar, openSwitchDialog } from "@/services/actions/commonActions";
import { useDispatch, useSelector, useUrlParams } from '@/services/hooks';
import SwapIcon2 from "@/assets/SwapIcon2.svg";
import SwapIcon from "@/assets/SwapIcon.svg";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FormattedMessage } from "react-intl";
import LightStreamPlayer from "@/components/LightStreamPlayer/LightStreamPlayer";
import SwitchHint from "@/components/common/Hint/SwitchHint";
import TagManager from "react-gtm-module";
import SmallGenreButton from "@/components/common/SmallGenreButton";
import CharacterList from "@/components/Scene/Image/CharacterList";
import React, { useEffect, useMemo, useState } from "react";
import { ApiService } from "@/services/api";
import ProgressBar from "@/components/Scene/Image/ProgressBar";
import { getLoginBonus } from "@/services/actions/userActions";

interface Props {
  isTutorial?: boolean;
  index?: number;
  scene: Scene;
  setScenes: React.Dispatch<React.SetStateAction<Scene[]>>;
  showDivider: boolean;
  bgColor?: string;
  isShowGenreSearch?: boolean
  sceneType: string
  isTwoColumn?: boolean
}

const MAX_IMAGE_SWITCH = 10;

export default function SceneWithImageComp(props: Props) {
  const { setUrlParam, getUrlParam } = useUrlParams();
  const { characters } = useSelector((state) => state.character);
  const [characterId, setCharacterId] = useState<number | null>(null);

  const dispatch = useDispatch();
  const { scene, setScenes } = props;
  const { user, daily_trial } = useSelector((state) => state.user);
  const isTwoColumn = props.isTwoColumn;
  const twoColumnsGenreStyle: Partial<BoxProps> = isTwoColumn ? {
    flexWrap: 'nowrap',
    sx: {
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none'
    },
  } : {}
  const twoColumnTitleStyle = isTwoColumn ? {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2, // 最大2行に制限
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.5em", /* 例: 1行の高さ */
    minHeight: "3em", /* 1.5em × 2行分の高さを確保 */
    marginTop: 0,
  } : {}

  const twoColumnsButtonStyle = isTwoColumn ? {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.MuiButton-root': {
      fontSize: '12px',
    }
  } : {};

  const [isLoading, setIsLoading] = useState(false);

  const imageSiwtchCount = daily_trial?.image_switch_count || 0;

  const [percent, setPercent] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setPercent(d => {
        if (!isLoading) { return 0; }
        return Math.min(d + 16, 99);
      })
    }, 800)
    return () => {
      setPercent(0);
      clearInterval(interval)
    }
  }, [isLoading])

  const sceneImages = useMemo(() => {
    return _.filter(scene?.scene_images, (item) => {
      return item.character_id === characterId;
    });
  }, [scene, characterId]);

  const isSwitchedCharacter = useMemo(() => {
    return _.includes(scene?.swapped_character_ids, characterId);
  }, [scene, characterId]);

  const isImageSwitchable = _.isEmpty(sceneImages);

  useEffect(() => {
    setCharacterId(characters[0]?.id)
  }, [characters]);

  const clickOpenSwitchDialog = () => {
    if (!characterId) {
      dispatch(openCharacterNewDialog());
      return;
    }
    // switch後ボタンを暗くするのに使用
    const onClose = () => {
      setScenes((before) => {
        const next = [...before];
        const sceneIndex = _.findIndex(next, { id: scene.id });
        if (sceneIndex !== -1) {
          const newScene = { ...scene };
          if (newScene.swapped_character_ids) {
            newScene.swapped_character_ids = [...newScene.swapped_character_ids, characterId as number];
          } else {
            newScene.swapped_character_ids = [characterId as number];
          }
          next[sceneIndex] = newScene;
          return next;
        }
        return before;
      })
    }
    dispatch(openSwitchDialog(scene, characterId, onClose));
  }

  const titleTag = (text: React.ReactNode, colorType: 1 | 2 | 3, filter?: string) => {
    const colorMap: { [key: number]: string } = {
      1: '#1A1A1A',
      2: '#707070',
      3: '#9E9E9E',
    };
    const backgroundColor = colorMap[colorType];

    return (
      <Box
        onClick={() => {
          if (filter) {
            setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), filter));
          }
        }}
        component="span"
        sx={{
          backgroundColor: backgroundColor,
          color: "#FFF",
          padding: "2px 4px",
          borderRadius: "4px",
          fontSize: "12px",
          fontWeight: 'bold',
          marginRight: "4px",
          cursor: 'pointer',
        }}
      >
        {text}
      </Box>
    )
  }
  const oneWeekAgo = dayjs().subtract(7, 'day');
  const twoWeekAgo = dayjs().subtract(14, 'day');

  const titleTags = () => {
    return <>
      {dayjs(scene.posted_at).isAfter(oneWeekAgo) && (
        titleTag(<FormattedMessage id="scene.tag.new" />, 1, '新作')
      )}

      {
        dayjs(scene.posted_at).isAfter(twoWeekAgo) &&
        dayjs(scene.posted_at).isBefore(oneWeekAgo) && (
          titleTag(<FormattedMessage id="scene.tag.semi_new" />, 1, '準新作')
        )
      }

      {scene.weekly_rank !== 0 && (
        titleTag(<FormattedMessage
          id="scene.tag.weekly_rank"
          values={{ num: scene.weekly_rank }} />, 2, '週間ランキング')
      )}

      {scene.overall_rank !== 0 && (
        titleTag(<FormattedMessage
          id="scene.tag.overall_rank"
          values={{ num: scene.overall_rank }} />, 2, '累計ランキング')
      )}

      {scene.genres?.some((genre) => genre.name === 'フェラ') && (
        titleTag(<FormattedMessage id="scene.tag.fellatio" />, 3)
      )}
    </>;
  }

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          position: 'relative',
          backgroundColor: props.bgColor,
        }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          marginTop={2}
          width="100%"
          sx={twoColumnTitleStyle}
        >
          {titleTags()}

          {scene.title}

          {scene.point === 0 && (<Box
            display="inline-flex"
            gap="4px"
            alignItems="center"
            sx={{
              minWidth: '48.8px',
              borderRadius: '4px',
              border: '1px solid #4CAF50',
              padding: '1px 2px',
            }}
          >
            <img src={SwapIcon2} alt="swap icon" width="14px" />
            <Typography variant="caption" color="#4CAF50">
              <FormattedMessage id="free" />
            </Typography>
          </Box>)}
        </Typography>

        {
          props.isShowGenreSearch && !scene.is_tutorial && (
            <Box
              padding={0}
              display="flex"
              alignItems="center"
              overflow="scroll"
              gap="6px"
              mt="12px"
              minHeight="23px"
            >
              <Typography
                variant="body1"
                fontSize="12px"
                flexShrink={0}
                sx={{
                  color: '#000000DE',
                  fontWeight: 400,
                }}
              >{isTwoColumn ? "" : "タグ："}</Typography>
              <Box
                display='flex'
                flexWrap='wrap'
                gap="6px"
                {...twoColumnsGenreStyle}
              >
                {
                  _.map(scene.genres, (genre) => {
                    return (
                      <SmallGenreButton
                        key={genre?.id}
                        label={genre?.name}
                        onClick={() => {
                          setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), genre?.name));
                        }}
                      />
                    )
                  })
                }
              </Box>
            </Box>
          )
        }

        <Box
          mt={2}
          display="flex"
          alignItems="start"
          justifyContent="center"
          flexDirection="column"
          gap={2}
        >
          <Box width="100%">
            <LightStreamPlayer
              is_tutorial={scene.is_tutorial}
              scene_id={scene.id}
              src={scene.view_uid || scene.uid}
              is_limited={scene.character?.is_limited}
              thumbnail_start_sec={scene.thumbnail_start_sec}
              scene_type={props.sceneType}
              filter={props.isShowGenreSearch ? getUrlParam('filter') || undefined : undefined}
              duration={scene.duration}
            />
          </Box>
          <Box sx={{ overflow: 'auto', width: "100%" }}>
            <CharacterList
              characterId={characterId}
              swappedCharacterIds={scene?.swapped_character_ids}
              createOnClick={(cId: number) => {
                return () => {
                  setCharacterId(cId);
                }
              }}
              iconSize={52}
            />
          </Box>

          <Stack
            width="100%"
            display="flex"
            gap={1}
          >
            {/* この顔でスイッチする */}
            <Stack
              justifyContent="start"
              gap={1}
            >
              <Button
                variant="contained"
                startIcon={isTwoColumn ? null :
                  <img
                    src={SwapIcon}
                    alt="swap icon"
                    width="18px"
                    style={{
                      filter: isSwitchedCharacter ? 'brightness(0.65)' : 'none',
                    }}
                  />
                }
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: props.isTutorial ? "tutorial_click_lets_switch_button" : "click_lets_switch_button",
                      user_id: user?.id,
                      original_scene_id: scene.id,
                    }
                  });
                  clickOpenSwitchDialog();
                }}
                disabled={isSwitchedCharacter}
                sx={{
                  '&.MuiButton-root': {
                    fontSize: '14px',
                  },
                  ...twoColumnsButtonStyle,
                }}
              >
                {isSwitchedCharacter || `${scene.point}pt`}
                <FormattedMessage id={isSwitchedCharacter ? "scene.image.switched_button" : "scene.image.switch_button"} />
              </Button>
            </Stack>

            {!!percent && (
              <ProgressBar
                value={percent}
              />
            )}

            {isImageSwitchable && (
              <>
                <Box>
                  <Button
                    onClick={() => {
                      if (!characterId) {
                        dispatch(openCharacterNewDialog());
                        return;
                      }
                      setIsLoading(true);
                      ApiService.switchImage(characterId, scene.id).then((data) => {
                        setScenes((before) => {
                          const originalSceneId = data[0]?.original_scene_id;
                          const next = [...before];
                          const sceneIndex = _.findIndex(next, { id: originalSceneId });
                          if (sceneIndex !== -1) {
                            const newScene = { ...scene };
                            newScene.scene_images = newScene.scene_images || [];
                            newScene.scene_images = [...newScene.scene_images, ...data];
                            next[sceneIndex] = newScene;
                            return next;
                          }
                          return before;
                        })
                        // TODO
                        const el = <FormattedMessage id="scene.image.dialog_message1" />
                        dispatch(openSnackbar(el, 'bottom'));
                      }).catch((err) => {
                        const el = <FormattedMessage id="scene.image.dialog_error_message" />
                        dispatch(openSnackbar(el, 'bottom'));
                        console.log(err);
                      }).finally(() => {
                        dispatch(getLoginBonus());
                        setIsLoading(false);
                      })
                    }}
                    size="small"
                    color="inherit"
                    disabled={isLoading || MAX_IMAGE_SWITCH - imageSiwtchCount <= 0}
                    sx={{
                      width: '100%',
                      zIndex: props.isTutorial ? 101 : 1,
                      postion: 'relative',
                      height: '20px',
                      fontSize: '14px',
                      justifyContent: 'right',
                      ...twoColumnsButtonStyle,
                    }}
                    endIcon={<ArrowForwardIosIcon
                      color="primary"
                      sx={{
                        paddingTop: '2px',
                        fontSize: '12px !important',
                      }}
                    />}
                  >
                    <Typography
                      color="textPrimary"
                      sx={{
                        fontSize: '12px',
                        lineHeight: '15px',
                        fontWeight: 400,
                      }}
                    >
                      <FormattedMessage id="scene.image.trial.button" />
                      {` (${MAX_IMAGE_SWITCH - imageSiwtchCount}/${MAX_IMAGE_SWITCH})`}
                    </Typography>
                    {props.isTutorial && props.index === 0 ? <SwitchHint /> : null}
                  </Button>
                </Box>
              </>
            )}
            {
              !isImageSwitchable && (
                <ButtonBase>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    padding="8px 10.5px"
                    sx={{
                      background: '#F5F5F5',
                      border: 'solid 1px #0000001F',
                      width: "100%",
                    }}
                    onClick={() => {
                      clickOpenSwitchDialog();
                    }}
                  >
                    <Stack
                      sx={{
                        flexGrow: 0,
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src={sceneImages[0].s3_url}
                        style={{
                          width: '50px',
                        }}
                      />
                    </Stack>
                    <Typography
                      color="primary"
                      fontSize="11px"
                      sx={{
                        minWidth: 'fit-content',
                      }}
                    >
                      <FormattedMessage id="scene.image.view_image" />
                    </Typography>
                  </Box>
                </ButtonBase>
              )
            }
          </Stack>
        </Box>

      </Card >
      {props.showDivider && <Divider sx={{ mt: 2 }} />}
    </>
  );
}
