import Axios from "axios";
import { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { CFStreamSignedItem, Scene, SwappedImageHistory, CheckReuploadingStatusResponse } from '@/services/models';
import { setTutorialStepSession } from '@/services/tutorial';
import { isAdult } from '@/constants';
import { getLocale } from '@/utils';
export const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
import axios from 'axios';

export const axiosWrapped = Axios.create({
  baseURL: apiEndpoint,
  headers: {
    'a_faceswich': isAdult ? '1' : '0',
    'a_locale': getLocale(),
  }
});

export const openSnackbar = (message: string | ReactElement, position: 'top' | 'bottom' = 'top') => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_SNACKBAR', message, position });
};

export const closeSnackbar = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_SNACKBAR' });
};

export const openAlertModal = (message: string | ReactElement) => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_ALERT_MODAL', message });
};

export const closeAlertModal = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_ALERT_MODAL' });
};

export const openRegistrationPopup = () => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_REGISTRATION_POPUP' });
};

export const closeRegistrationPopup = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_REGISTRATION_POPUP' });
};

export const openPlanDialog = (use_type: string) => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_PLAN_DIALOG', use_type });
};

export const closePlanDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_PLAN_DIALOG' });
};

// 廃止予定
export const openCharacterSwapDialog = (scene: Scene) => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_CHARACTER_SWAP_DIALOG', scene_id: scene.id, point: scene.point, is_tutorial: scene.is_tutorial });
};

// 廃止予定
export const openCharacterSwapDialogWithSample = (video: CFStreamSignedItem) => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_CHARACTER_SWAP_DIALOG_WITH_SAMPLE', video_uid: video.video_uid });
};

// 廃止予定
export const closeCharacterSwapDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_CHARACTER_SWAP_DIALOG' });
};

export const openSwitchDialog = (scene: Scene, character_id: number | null, onClose?: () => void) => (dispatch: Dispatch) => {
  dispatch({
    type: 'OPEN_SWITCH_DIALOG',
    scene: scene,
    character_id,
    onClose: onClose ? onClose : () => {},
  });
};

export const closeSwitchDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_SWITCH_DIALOG' });
};

export const openSwitchImageDialog = (swapped_image_history: SwappedImageHistory) => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_SWITCH_IMAGE_DIALOG', swapped_image_history });
};

export const closeSwitchImageDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_SWITCH_IMAGE_DIALOG' });
};

export const openSwitchedFeedbackDialog = (scene_id: Scene['id']) => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_SWITCHED_FEEDBACK_DIALOG', scene_id });
};

export const closeSwitchedFeedbackDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_SWITCHED_FEEDBACK_DIALOG' });
};

export const setReferralCode = (referral_code: string) => (dispatch: Dispatch) => {
  dispatch({ type: 'SET_REFERRAL_CODE', referral_code });
};

export const setGentamaCode = (gentama_code: string) => (dispatch: Dispatch) => {
  dispatch({ type: 'SET_GENTAMA_CODE', gentama_code });
};

export const setMonetrackCode = (monetrack_code: string) => (dispatch: Dispatch) => {
  dispatch({ type: 'SET_MONETRACK_CODE', monetrack_code });
};

export const setReferrer = (referrer: string) => (dispatch: Dispatch) => {
  dispatch({ type: 'SET_REFERRER', referrer });
};

export const setProgressPolling = (value: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: 'SET_PROGRESS_POLLING', value });
};

export const toggleProgressComplete = () => (dispatch: Dispatch) => {
  dispatch({ type: 'TOGGLE_PROGRESS_COMPLETE' });
};

export const setReuploadingPolling = (polling: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: 'SET_REUPLOADING_POLLING', polling });
};

export const setReuploadingStatus = (statuses: CheckReuploadingStatusResponse['swaps']) => (dispatch: Dispatch) => {
  dispatch({ type: 'SET_REUPLOADING_STATUS', statuses });
};

export const fetchNotices = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await axiosWrapped.get('/api/v1/notice', {withCredentials: true});
    dispatch({ type: 'FETCH_NOTICE_SUCCESS', payload: data });
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      dispatch({ type: 'FETCH_NOTICE_FAIL', payload: e.message });
    }
  }
};

export const readNotice = (noticeId: string) => async (dispatch: Dispatch) => {
  try {
    await axiosWrapped.post(`/api/v1/notice/${noticeId}/read`, null, {withCredentials: true});
    dispatch({ type: 'READ_NOTICE_SUCCESS', noticeId: noticeId });
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      dispatch({ type: 'READ_NOTICE_FAIL', payload: e.message });
    }
  }
};

export const setTutorialStep = (step: string) => (dispatch: Dispatch) => {
  setTutorialStepSession(step);
  dispatch({ type: 'SET_TUTORIAL_STEP', step });
};

export const updateMaintenanceMode = (value: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: 'UPDATE_MAINTENANCE_SUCCESS', value });
}

export const openLoginBonusDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_LOGIN_BONUS_DIALOG' });
};

export const closeLoginBonusDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_LOGIN_BONUS_DIALOG' });
};

export const openTimesaleDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_TIMESALE_DIALOG' });
};

export const closeTimesaleDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_TIMESALE_DIALOG' });
};

export const showTimesaleCountdown = () => (dispatch: Dispatch) => {
  dispatch({ type: 'SHOW_TIMESALE_COUNTDOWN' });
};

export const closeTimesaleCountdown = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_TIMESALE_COUNTDOWN' });
};

export const closeCharacterNewDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_CHARACTER_NEW_DIALOG' });
};

export const openCharacterNewDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_CHARACTER_NEW_DIALOG' });
};

export const openGrandpayInfoInputDialog = (props: { item_id: number } | { plan_id: number }) => (dispatch: Dispatch) => {
  dispatch({ type: 'OPEN_GRANDPAY_INFO_INPUT_DIALOG', ...props });
};

export const closeGrandpayInfoInputDialog = () => (dispatch: Dispatch) => {
  dispatch({ type: 'CLOSE_GRANDPAY_INFO_INPUT_DIALOG' });
};