import { Favorite, FileUpload, OndemandVideo } from "@mui/icons-material"
import { Box, Stack, Typography } from "@mui/material"
import { FormattedMessage } from "react-intl"
import SwapIcon from "@/assets/SwapIcon3.svg";
import ParallelSwitch from "@/assets/ParallelSwitch.svg";
import { isAdult } from "@/constants";

const renderPlanItem = (props: {
  icon: JSX.Element,
  title: JSX.Element,
  description: JSX.Element,
}) => {
  return (
    <Stack
      gap={2}
      display="flex"
      direction="row"
      alignItems="center"
    >
      {props.icon}
      <Typography typography="caption">{props.title}</Typography>
      <Typography typography="body2" fontWeight="bold">{props.description}</Typography>

    </Stack>
  )
}

type CanBronzePlanProps = {
  cardColor?: string
}
const CanBronzePlan = ({ cardColor = "#FFFFFF" }: CanBronzePlanProps) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="13px"
      >
        <Stack
          gap="10px"
          width="50%"
          padding="10px 0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: cardColor,
            borderRadius: '8px',
          }}
        >
          <img style={{ width: '48px' }} src={SwapIcon} alt="swap icon" />
          <Typography variant="subtitle2" color="textSecondary">
            <FormattedMessage id="demo.switch_title" />
          </Typography>
          <Typography variant="subtitle2" fontSize="15px">
            <FormattedMessage id="demo.plan.switch_description1" />
            <br />
            <FormattedMessage id="demo.plan.switch_description2" />
          </Typography>
        </Stack>

        <Stack
          width="50%"
          gap="10px"
          padding="10px 0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: cardColor,
            borderRadius: '8px',
          }}
        >
          <FileUpload color="primary" sx={{ fontSize: '48px' }} />
          <Typography variant="subtitle2" color="textSecondary">
            <FormattedMessage id="demo.max_character" />
          </Typography>
          <Typography variant="subtitle2" fontSize="15px">
            <FormattedMessage id="demo.subscription_max_character_num" /><br />{"　"}
          </Typography>
        </Stack>
      </Box>


      <Stack gap="4px" display="flex" justifyContent="center">
        {renderPlanItem({
          icon: <Favorite color="primary" />,
          title: <FormattedMessage id="demo.favorite_title" />,
          description: <FormattedMessage id="demo.favorite_description" />,
        })}
        {!!isAdult && renderPlanItem({
          icon: <OndemandVideo color="primary" />,
          title: <FormattedMessage id="demo.av_title" />,
          description: <FormattedMessage id="demo.av_description" />,
        })}
        {renderPlanItem({
          icon: <img src={ParallelSwitch} alt="parallel swap icon" />,
          title: <FormattedMessage id="demo.parallel_switch_title" />,
          description: <FormattedMessage id="demo.parallel_switch_description" />,
        })}
      </Stack>
    </>
  )
}

export default CanBronzePlan